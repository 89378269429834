<template>
  <div class="space-edit fill-height">
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>
      <v-container v-show="isLoading">
        <m-loading type="circle" :loading="true"></m-loading>
      </v-container>
      <v-container v-show="!isLoading" class="space-edit__content">
        <v-row mb-2>
          <v-col class="d-flex flex-column pa-0">
            <button class="mt-10 back-button-container" @click="goBack" data-cy="backButton">
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="back-button-text"> {{ $t('spaces.create.back') }} </span>
            </button>
            <div style="display: flex">
              <c-page-heading
                class="heading"
                :heading="$t('spaces.edit.edit')"
                :subHeading="$t('spaces.edit.space')"
              ></c-page-heading>
              <div v-if="values.name" class="heading space-name-container">
                <h1 class="space-name-content">
                  <span style="font-weight: 200; color: #1f2329" data-cy="space-name-header">
                    : <span style="color: #807ef0">{{ values.name }}</span>
                  </span>
                </h1>
              </div>
            </div>
            <span data-cy="editYourSpace" class="subheading">{{
              $t('spaces.edit.editYourSpace')
            }}</span>

            <!-- Content -->

            <v-row>
              <v-col sm="12">
                <div>
                  <m-form ref="form" data-cy="space-form" :buttons="formButtons">
                    <m-tabs v-model="tab">
                      <m-tab data-cy="detailsTab" href="#details">
                        {{ $t('spaces.create.details') }}
                      </m-tab>
                      <m-tab data-cy="attendeesTab" href="#attendees">
                        {{ $t('spaces.create.attendees') }}
                      </m-tab>
                      <m-tab data-cy="brandingTab" href="#branding">
                        {{ $t('spaces.create.branding') }}
                      </m-tab>
                    </m-tabs>

                    <m-tabs-items v-model="tab">
                      <m-tab-item value="details">
                        <c-space-details-tab
                          :ecosystemId="ecosystemId"
                          :spaceId="spaceId"
                          v-model="values"
                          @accessChanged="accessChanged"
                        ></c-space-details-tab>
                      </m-tab-item>
                      <m-tab-item value="attendees">
                        <validation-provider>
                          <c-space-attendees-tab
                            v-model="values.attendees"
                            :hideBulkAction="tab === 0"
                            :ecosystem="ecosystem"
                            @bulk-action-panel:update="toggleBulkActionPanel"
                          />
                        </validation-provider>
                      </m-tab-item>
                      <m-tab-item value="branding">
                        <c-space-branding-tab
                          :ecosystem="ecosystem"
                          :space="space"
                          :templateId="values.templateId"
                          :templateName="templateName"
                          @setBranding="setBranding"
                        />
                      </m-tab-item>
                    </m-tabs-items>

                    <template #footer="{ invalid, dirty }">
                      <!-- Teleport action bar to the bottom of the page -->
                      <portal v-if="showBulkActionPanel" selector="#routerViewport">
                        <m-action-bar>
                          <template #action>
                            <m-button
                              data-cy="submit-button"
                              :disabled="!dirty || invalid"
                              :label="$t('spaces.edit.updateSpace')"
                              @click="onSubmit"
                            ></m-button>
                          </template>
                        </m-action-bar>
                      </portal>
                    </template>
                  </m-form>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import {
  MLoading,
  MTabs,
  MTab,
  MTabItem,
  MTabsItems,
  MForm,
  MActionBar,
  MButton,
} from 'gatherings-storybook'
import { Portal } from '@linusborg/vue-simple-portal'
import { ValidationProvider } from 'vee-validate'
import UnsavedChangesMixin from '@/mixins/UnsavedChangesMixin'

export default {
  mixins: [UnsavedChangesMixin],
  components: {
    MLoading,
    MTabs,
    MTab,
    MTabItem,
    MTabsItems,
    MForm,
    MActionBar,
    MButton,
    Portal,
    ValidationProvider,
  },
  props: ['ecosystemId', 'spaceId'],
  data: () => ({
    tab: null,
    space: {},
    values: {
      id: '',
      name: '',
      templateId: '',
      colour: '',
      timeZone: '',
      startTime: undefined,
      endTime: undefined,
      isEcosystemOpen: true,
      attendees: [],
      branding: [],
    },
    spaceTemplates: [],
    ecosystem: {},
    timeZones: [],
    showBulkActionPanel: true,
    formButtons: {
      Submit: { show: false },
    },
  }),
  async mounted() {
    await this.$store.dispatch('ui/toggleLoading', {
      ...this.$store.getters['ui/loading'],
      isLoading: true,
    })
    await this.fetchInitialData()
    await this.populateValues()
    await this.$store.dispatch('ui/toggleLoading', {
      ...this.$store.getters['ui/loading'],
      isLoading: false,
    })
    this.watchForUnsavedChanges('values', this.onUnsavedChanges, {
      title: this.$t('spaces.unsavedChanges.title'),
      subMessage: this.$t('spaces.unsavedChanges.subMessage'),
      buttons: [
        {
          label: this.$t('spaces.unsavedChanges.buttons.dontSave'),
          type: 'cancel',
          variant: 'secondary',
        },
        {
          label: this.$t('spaces.unsavedChanges.buttons.save'),
          type: 'confirm',
          icon: 'chevron-right-stroke',
          iconRight: true,
        },
      ],
    })
  },
  methods: {
    async fetchInitialData() {
      await this.$store.dispatch('spaces/fetchTemplates')
      this.spaceTemplates = await this.$store.getters['spaces/templates'].map(g => {
        return {
          value: g.id,
          text: g.name,
        }
      })

      await this.$store.dispatch('ecosystems/fetchById', this.ecosystemId)
      await this.$store.dispatch('ecosystems/fetchUsers', this.ecosystemId)

      this.ecosystem = await this.$store.getters['ecosystems/getById'](this.ecosystemId)

      await this.$store.dispatch('ecosystems/fetchSpaceById', {
        ecosystemId: this.ecosystemId,
        spaceId: this.spaceId,
      })

      this.space = await this.$store.getters['ecosystems/getSpaceById'](
        this.ecosystemId,
        this.spaceId
      )

      this.timeZones = await this.$store.getters['timeZones/all']
      this.values.timeZone =
        this.values.timeZone ??
        (await this.$store.getters['timeZones/default']({
          ecosystemId: this.ecosystemId,
        }))
    },
    accessChanged() {
      this.$refs.form.markAsDirty()
    },
    async onSubmit() {
      this.values.isWorkspaceOpen = this.values.isEcosystemOpen

      const data = {
        ...this.values,
        attendees: this.values.attendees.map(attendee => ({
          id: attendee.id,
          givenName: attendee.givenName,
          surname: attendee.surname,
          email: attendee.email,
          company: attendee.company,
          jobTitle: attendee.jobTitle,
          host: attendee.host,
        })),
      }

      this.$store.dispatch('ui/toggleLoading', { show: true })

      try {
        await this.$store.dispatch('ecosystems/updateSpaceStyle', {
          ecosystemId: this.ecosystemId,
          spaceId: this.values.id,
          data: { styles: this.values.branding },
        })
      } catch (error) {
        this.showMessage(this.$t('errors.sorrySomethingWentWrong'), false)
      }

      try {
        await this.$store.dispatch('ecosystems/updateSpace', {
          ecosystemId: this.ecosystemId,
          data,
        })

        this.showMessage('Space Updated')
      } catch (error) {
        this.showMessage(this.$t('errors.sorrySomethingWentWrong'), false)
      }

      // Ensure the unsaved changes modal isn't triggered again
      this.resetUnsavedChanges()

      this.$router.push({ path: `/ws/${this.ecosystemId}/spaces` })
    },
    showMessage(message, success = true) {
      this.$store.dispatch('ui/launchSnackbar', {
        color: success ? 'green' : 'error',
        message: message,
        buttonColor: 'white',
      })
    },
    async populateValues() {
      Object.keys(this.space).forEach(key => {
        if (this.values[key] !== undefined) {
          this.values[key] = this.space[key]
        }
        if (key === 'accessType') {
          this.values.isEcosystemOpen = !!this.space.accessType // 0 = invite only, 1 = open
        }
        if (key === 'template' && this.space.template && this.space.template.id) {
          this.values.templateId = this.space.template.id
        }
        // Map extra values from ecosystem.users into attendees array
        if (key === 'attendees') {
          // find the matching user in the ecosystem users and merge the host detail in - other attendee data is inaccurate...
          this.values.attendees = this.space.attendees
          for (const attendee of this.values.attendees) {
            let ecosystemUser = this.ecosystem.users.filter(u => u.id === attendee.id)[0]
            if (ecosystemUser) {
              this.values.attendees = [
                ...this.values.attendees.map(attendee =>
                  attendee.id !== ecosystemUser.id
                    ? attendee
                    : { ...ecosystemUser, host: attendee.host }
                ),
              ]
            }
          }
        }
      })
    },
    setBranding(branding) {
      this.values.branding = branding

      setTimeout(() => {
        this.$refs.form.markAsDirty()
      }, 100)
    },
    goBack() {
      this.$router.go(-1)
    },
    toggleBulkActionPanel(value) {
      this.showBulkActionPanel = !value
    },
    /**
     * A function that is called when the user attempts to navigate away from the current page with unsaved changes
     * @param {string} result either 'confirm' | 'cancel'
     * @param {*} next call to continue navigating to the next route
     */
    async onUnsavedChanges(result, next) {
      // The user just wants to exit
      if (result === 'cancel') {
        next()
        return
      }

      const { validate } = this.$refs.form.validator
      const isValid = await validate()

      // The user wants to save change, validate the form before attempting to save
      if (!isValid) {
        // Can't submit so just prevent them from navigating away
        return
      }

      // Save changes
      await this.onSubmit()

      // Continue navigation
      next()
    },
  },
  computed: {
    templateName() {
      let match = this.spaceTemplates?.find(template => template.value === this.values.templateId)
      return match ? match.text : ''
    },
    isLoading() {
      return this.$store.getters['ui/loading'].isLoading
    },
  },
}
</script>
<style scoped lang="scss">
.space-edit {
  display: flex;
  flex-direction: column;

  .container:not(.action-bar) {
    height: 100%;
  }
}
.space-edit__content {
  :deep(*) {
    font-family: Mulish !important;
  }

  gap: 24px;
  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }
  @media (min-width: 1264px) {
    max-width: 1120px;
  }
  @media (min-width: 1904px) {
    max-width: 1240px;
  }
  form.v-form {
    margin: -16px;
  }
}
.heading {
  margin-top: 32px !important;
}
.space-name-container {
  max-width: 65%;
  white-space: nowrap;
}
.space-name-content {
  font-size: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #807ef0;
}
.subheading {
  margin-top: -48px;
  margin-bottom: 32px;
  font-size: 1.285rem;
  line-height: 1.5;
}
.back-button-container {
  display: flex;
  align-items: center;
  max-width: fit-content;
  margin-left: -0.375rem;
  cursor: pointer;
}
.back-button-text {
  color: #807ef0;
  font-weight: 700;
  font-size: 1.1429rem;
  line-height: 24px;
}
.tab-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #363332;
  text-transform: none;
}

.tabs {
  border-bottom: 1px solid #dce2e9;
}
</style>
