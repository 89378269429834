import { isEqual } from 'lodash'

export default {
  data: () => ({
    hasUnsavedChanges: false,
    modalOptions: {},
    initialValue: null,
    onModalClose: () => {},
  }),
  methods: {
    watchForUnsavedChanges(item, onModalClose, modalOptions = null) {
      this.onModalClose = onModalClose
      this.modalOptions = modalOptions || this.modalOptions
      this.initialValue = Object.assign({}, this[item])

      const stop = this.$watch(
        item,
        newVal => {
          // Check the item has actually changed
          this.hasUnsavedChanges = !isEqual(newVal, this.initialValue)

          // No point in watching anymore as there are unsaved changes
          if (this.hasUnsavedChanges) stop()
        },
        { deep: true }
      )

      window.addEventListener('beforeunload', this.onWindowUnload)
    },
    async notifyUserOfUnsavedChanges() {
      return this.$store.dispatch('modal/show', this.modalOptions)
    },
    onWindowUnload(event) {
      if (this.hasUnsavedChanges) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    resetUnsavedChanges() {
      this.hasUnsavedChanges = false
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      try {
        await this.notifyUserOfUnsavedChanges()

        await this.onModalClose('confirm', next)
      } catch (e) {
        await this.onModalClose('cancel', next)
      }

      window.removeEventListener('beforeunload', this.onWindowUnload)
    } else {
      next()
    }
  },
}
